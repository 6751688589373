import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from '@prio365/prio365-react-library';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditTimeRecordContextType, OfficeRole } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import { TimeRecord, TimeRecordsFilter } from '../../../models/TimeRecord';
import { getCompaniesByIdState } from '../../../apps/main/rootReducer';
import { openTimeAndLeaveManagementDrawer } from '../../timeAndLeaveManagement/actions';
import { syncGlobalProjects } from '../../projects/actions';
import NavigationBar from '../../../components/NavigationBar';
import DocumentTemplateFormWrapper, {
  DocumentTemplateFormWrapperRef,
} from '../../documents/components/DocumentTemplateFrom/DocumentTemplateFormWrapper';
import TimeRecordsPage from './TimeRecordsPage';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import { FilterBar } from '../../../components/Filter/FilterBar';
import DocumentTemplateCategoryContextProvider from '../../documents/components/DocumentTemplateCategoryContextProvider';
import DocumentTemplateTagContextProvider from '../../documents/components/DocumentTemplateTagContextProvider';
import { DocumentTemplatePreview } from '../../documents/components/DocumentTemplateFrom/DocumentTemplatePreview';
import moment from 'moment';

const useStyles = makePrioStyles(() => ({
  root: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    overflow: 'hidden',
  },
}));

export interface TimeRecordsModuleRef {
  setDeleteSelectionModalVisible: (value: boolean) => void;
}

interface TimeRecordsModuleProps {
  contextType: EditTimeRecordContextType;
  officeRoles?: OfficeRole[];
  isNavBarUnvisible?: boolean;
  setSelectedTimeRecordsMe?: (value: string[]) => void;
}

export const TimeRecordsModule = forwardRef<
  TimeRecordsModuleRef,
  TimeRecordsModuleProps
>((props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const formWrapperRef = useRef<DocumentTemplateFormWrapperRef>(null);
  const { contextType, officeRoles, isNavBarUnvisible } = props;

  const { officeId } = useParams();
  const dispatch = useDispatch();
  //#endregion
  //#region ------------------------------ States / Attributes / Selectors
  const companiesById = useSelector(getCompaniesByIdState);

  const [selectedTimeRecords, setSelectedTimeRecords] = useState<TimeRecord[]>(
    []
  );
  const [allTimeRecords, setAllTimeRecords] = useState<TimeRecord[]>([]);
  const [thumbnailURL, setThumbnailURL] = useState<String[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [dateRange, setDateRange] = useState<TimeRecordsFilter>({
    from: null,
    to: null,
  });
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const [reportDrawerVisible, setReportDrawerVisible] = useState(false);

  const onCreateDocumentSuccess = () => {
    setReportDrawerVisible(false);
  };
  const onStartCreateDocument = () => {
    setReportDrawerVisible(false);
  };

  const onCancelReportDrawer = () => {
    setReportDrawerVisible(false);
    setSelectedTemplate({});
    setThumbnailURL([]);
  };

  const showTimeRecordDrawer = () =>
    dispatch(openTimeAndLeaveManagementDrawer({ tab: 'timeRecords' }));

  const calculateRelativeDateRange = (relativeValue: string) => {
    const regex = /([ltn])(\d*)([mywdqhil])/;
    const match = relativeValue.match(regex);

    if (!match) return null;

    const type = match[1];
    const amount = match[2] ? parseInt(match[2], 10) : 1;
    const unit = match[3];

    const range: {
      from: moment.Moment | null;
      to: moment.Moment | null;
    } = { from: null, to: null };

    if (type === 't') {
      switch (unit) {
        case 'y':
          range.from = moment().utc().startOf('year');
          range.to = moment().utc().endOf('year');
          break;
        case 'q':
          range.from = moment().utc().startOf('quarter');
          range.to = moment().utc().endOf('quarter');
          break;
        case 'm':
          range.from = moment().utc().startOf('month');
          range.to = moment().utc().endOf('month');
          break;
        case 'w':
          range.from = moment().utc().startOf('isoWeek');
          range.to = moment().utc().endOf('isoWeek');
          break;
        case 'd':
          range.from = moment().utc().startOf('day');
          range.to = moment().utc().endOf('day');
          break;
        default:
          break;
      }
    } else if (type === 'l') {
      switch (unit) {
        case 'y':
          range.from = moment().utc().subtract(amount, 'years').startOf('year');
          range.to = moment().utc().subtract(1, 'years').endOf('year');
          break;
        case 'q':
          range.from = moment()
            .utc()
            .subtract(amount, 'quarters')
            .startOf('quarter');
          range.to = moment().utc().subtract(1, 'quarters').endOf('quarter');
          break;
        case 'm':
          range.from = moment()
            .utc()
            .subtract(amount, 'months')
            .startOf('month');
          range.to = moment().utc().subtract(1, 'months').endOf('month');
          break;
        case 'w':
          range.from = moment()
            .utc()
            .subtract(amount, 'weeks')
            .startOf('isoWeek');
          range.to = moment().utc().subtract(1, 'weeks').endOf('isoWeek');
          break;
        case 'd':
          range.from = moment().utc().subtract(amount, 'days').startOf('day');
          range.to = moment().utc().subtract(1, 'days').endOf('day');
          break;
        default:
          break;
      }
    } else if (type === 'n') {
      switch (unit) {
        case 'y':
          range.from = moment().utc().startOf('year').add(1, 'year');
          range.to = moment().utc().add(amount, 'years').endOf('year');
          break;
        case 'q':
          range.from = moment().utc().startOf('quarter').add(1, 'quarter');
          range.to = moment().utc().add(amount, 'quarters').endOf('quarter');
          break;
        case 'm':
          range.from = moment().utc().startOf('month').add(1, 'month');
          range.to = moment().utc().add(amount, 'months').endOf('month');
          break;
        case 'w':
          range.from = moment().utc().startOf('isoWeek').add(1, 'week');
          range.to = moment().utc().add(amount, 'weeks').endOf('isoWeek');
          break;
        case 'd':
          range.from = moment().utc().startOf('day').add(1, 'day');
          range.to = moment().utc().add(amount, 'days').endOf('day');
          break;
        default:
          break;
      }
    }
    return range;
  };

  const handleSearchStringChange = (searchString: string) => {
    if (searchString !== null) {
      const fromMatch = searchString?.match(/Data\.Day\sge\s'([^']+)'/);
      const toMatch = searchString?.match(/Data\.Day\sle\s'([^']+)'/);
      const relativeMatch = searchString?.match(/Data\.Day\srel\s'([^']+)'/);

      let newDateRange: TimeRecordsFilter = {
        from: null,
        to: null,
      };

      if (fromMatch || toMatch) {
        newDateRange = {
          from: fromMatch ? moment.utc(fromMatch[1]) : null,
          to: toMatch ? moment.utc(toMatch[1]).endOf('day') : null,
        };
      } else if (relativeMatch) {
        const relativeValue = relativeMatch[1];

        const calculatedRange = calculateRelativeDateRange(relativeValue);
        if (calculatedRange) {
          newDateRange.from = calculatedRange.from;
          newDateRange.to = calculatedRange.to;
        }
      } else {
        newDateRange = {
          from: moment.utc('1900-01-01T00:00:00.000Z'),
          to: moment.utc('2200-12-31T23:59:59.999Z'),
        };
      }
      if (
        (newDateRange?.from && !newDateRange.from.isSame(dateRange?.from)) ||
        (newDateRange?.to && !newDateRange.to.isSame(dateRange?.to))
      ) {
        setDateRange(newDateRange);
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(syncGlobalProjects());
  }, [dispatch]);
  //#endregion

  return (
    <div className={classes.root}>
      {!isNavBarUnvisible && (
        <NavigationBar>
          <Button iconProp={['fal', 'pencil']} onClick={showTimeRecordDrawer}>
            <span>{t('common:navigationBar.recordTime')}</span>
          </Button>
          <Button
            disabled={!(allTimeRecords?.length > 0)}
            onClick={() => setReportDrawerVisible(true)}
            iconProp={['fal', 'file-excel']}
            type="default"
          >
            <span>{t('timeRecords:navigationBar.createReport')}</span>
          </Button>
        </NavigationBar>
      )}

      <TimeRecordsPage
        className={classes.content}
        officeRoles={officeRoles}
        contextType={contextType}
        setSelectedTimeRecordsObjects={setSelectedTimeRecords}
        setTimeRecords={setAllTimeRecords}
        setReportDrawerVisible={setReportDrawerVisible}
        setSearchString={handleSearchStringChange}
      />

      <Drawer
        title={t('timeRecords:navigationBar.createReport')}
        closable={true}
        onClose={onCancelReportDrawer}
        visible={reportDrawerVisible}
        customWidth={800}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button type="default" onClick={onCancelReportDrawer}>
              {t('common:actions.cancel')}
            </Button>
            <Button
              onClick={() => formWrapperRef.current?.onFinish()}
              disabled={!isFormValid}
              style={{
                marginLeft: '12px',
              }}
            >
              {t('common:actions.save')}
            </Button>
          </div>
        }
        mask={
          <DocumentTemplatePreview
            thumbnailURL={thumbnailURL}
            selectedTemplate={selectedTemplate}
          />
        }
      >
        <DocumentTemplateTagContextProvider>
          <DocumentTemplateCategoryContextProvider>
            <FilterContextProvider
              options={{
                enabled: false,
                staleTime: 1000 * 60 * 60 * 24,
              }}
              searchType="documentTemplates"
              customDefaultSearchParameters={[
                {
                  parameterName: 'Data.IsReport',
                  defaultValue: 'true',
                  defaultMethod: 'eq',
                },
              ]}
              disableURLQuery={true}
            >
              <FilterBar hiddenPickers={['Data.IsReport']} />
              <DocumentTemplateFormWrapper
                showOnlyReports={true}
                timeRecords={
                  selectedTimeRecords.length > 0
                    ? selectedTimeRecords
                    : allTimeRecords
                }
                filter={dateRange}
                onSuccess={onCreateDocumentSuccess}
                onStartCreateDocument={onStartCreateDocument}
                onCancel={onCancelReportDrawer}
                onThumbnailURLChange={setThumbnailURL}
                onSelectedTemplateChange={setSelectedTemplate}
                ref={formWrapperRef}
                setIsFormValid={setIsFormValid}
                projectPickerFilter={({ isArchived, companyId }) => {
                  if (isArchived) {
                    return false;
                  }
                  if (officeId) {
                    const company = companiesById[companyId];
                    if (company && company.companyType === 'InternalCompany') {
                      const { offices } = company;
                      const mainOfficeId = offices.find(
                        (office) => office.isMainOffice
                      )?.officeId;
                      if (!mainOfficeId) {
                        return false;
                      }
                      if (mainOfficeId !== officeId) {
                        return false;
                      }
                    }
                  }
                  return true;
                }}
              />
            </FilterContextProvider>
          </DocumentTemplateCategoryContextProvider>
        </DocumentTemplateTagContextProvider>
      </Drawer>
    </div>
  );
});

export default TimeRecordsModule;
